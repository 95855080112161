import React from 'react'

import { withApi } from '../../api/ApiContext'
import IntlUtil from '../../utils/IntlUtil'
import { ErrorPage } from './ErrorPage'
import ReactGA from 'react-ga'
import PageData from '../../utils/PageData'
import queryString from 'query-string'
import { withThemeSwitch } from '../../assets/styles/theme'

let isAppFrameRequired = true

const defaultRenderStatusCode = (props) => {
  return <ErrorPage {...props} />
}

const createPageWrapper = (config) => {
  const {
    AppFrame,
    PopupWrapper,
    getComponentByName,
    renderStatusCode = defaultRenderStatusCode,
    defaultLabels,
  } = config
  IntlUtil.init({ defaultLabels })

  class PageWrapper extends React.Component {
    constructor(props) {
      super(props)

      const { location } = props
      let { language: browserLanguage } = navigator
      if (browserLanguage) browserLanguage = browserLanguage.substr(0, 2)

      this.state = {
        propsMember: props.member,
        location,
        language: (browserLanguage || '').toLowerCase(),
        direction: 'ltr',
      }
    }

    componentDidMount() {
      this.mounted = true
      this.fetchPageData()

      const {
        location: { hash },
      } = this.props

      if (hash && hash.length) {
        this.fetchPopupData()
      }

      const parsedUrl = queryString.parse(window.location.search)
      if (parsedUrl.xType === 'print') {
        this.setState({
          isPrint: true,
          currentOrder: parsedUrl.currentOrder,
        })
      }
    }

    static getDerivedStateFromProps(props, prevState) {
      let result

      if (props.member && props.member !== prevState.propsMember) {
        const member = { ...prevState.member, ...props.member }
        result = { member, propsMember: props.member }
      }

      if (props.location !== prevState.location) {
        const { state } = props.location

        const isPseudo = state && state.pseudoRoute !== undefined
        const { action } = props.history

        if (!isPseudo || action === 'POP') {
          result = { ...result, location: props.location }
        }
      }

      return result || null
    }

    componentWillUnmount() {
      this.mounted = false
    }

    componentDidUpdate(prevProps) {
      let {
        history,
        location: { hash, pathname: url, state = {} },
      } = this.props
      let {
        location: { hash: prevHash, pathname: prevUrl, state: prevState = {} },
      } = prevProps

      if (url.endsWith('/')) url = url.slice(0, -1)
      if (prevUrl.endsWith('/')) prevUrl = prevUrl.slice(0, -1)

      let stateChanged = state.code !== prevState.code

      const hasClosedLightBox =
        prevHash && prevHash.length && (!hash || hash.length === 0)
      if (hasClosedLightBox && prevState.notClosable) {
        history.push(url + prevHash, prevState)
        return
      }

      if (url !== prevUrl || stateChanged) {
        const isPseudo = state && state.pseudoRoute !== undefined
        const { action } = this.props.history

        if (isPseudo && action !== 'POP') {
          const { _id } = state.pseudoRoute
          this.propagateRouteChange({ props: { _id } })
        } else {
          this.fetchPageData()
        }
      }
      if (hash && hash.length > 0 && (hash !== prevHash || stateChanged)) {
        this.fetchPopupData()
      }
    }

    fetchPageData = async () => {
      const {
        location: { pathname: url, search, state: { isSilent } = {} },
      } = this.props

      if (!isSilent) {
        this.setState({ isPageLoaded: false })
      }

      try {
        const { api } = this.props
        const { language: stateLanguage } = this.state

        if (this.cancelPreviousPageFetch) {
          this.cancelPreviousPageFetch()
        }

        const { request, cancel } = api.connector.get(url + search, {
          headers: {
            'x-type': 'page',
            'x-appframe-required': isAppFrameRequired,
            'x-referer': encodeURI(
              decodeURI(`${window.location.pathname}${window.location.hash}`),
            ),
            'x-language': stateLanguage,
          },
        })
        this.cancelPreviousPageFetch = cancel

        const pageData = await request

        this.cancelPreviousPageFetch = null

        if (!this.mounted) {
          return
        }

        let { component: componentName } = pageData

        let component = getComponentByName(componentName)
        if (component && component.import) {
          component = await component.import()
          component = component.default
        }

        pageData.component = component
        pageData.componentName = componentName

        let { appFrame, member } = pageData
        let {
          pageData: { appFrame: prevAppFrame } = {},
          member: prevMember,
        } = this.state

        appFrame = pageData.appFrame = appFrame || prevAppFrame
        member = member || prevMember

        if (appFrame !== undefined) {
          isAppFrameRequired = false
        }

        let {
          settings: {
            labels,
            language: { currency = 'EUR' } = {},
            languages,
          } = {},
        } = appFrame || {}

        currency = currency.toUpperCase()

        let { language } = member || {}
        language = language || stateLanguage

        let locales = languages.map(({ locale }) => locale)
        let selectedLanguage =
          languages.find(({ locale }) => locale.slice(0, 2) === language) ||
          languages[0]
        const { locale, rtl } = selectedLanguage

        let direction = rtl ? 'rtl' : 'ltr'

        if (this.state.direction !== direction)
          this.props.onThemeChange({ direction })

        PageData.appFrame = appFrame
        IntlUtil.labels = labels
        IntlUtil.supportedLocales = locales
        IntlUtil.locale = locale
        IntlUtil.currency = currency
        document.documentElement.lang = locale

        let { routeKey = 0, contentKey = 0 } = this.state
        contentKey += 1

        if (!isSilent) routeKey += 1

        this.setState(
          {
            direction,
            language,
            member,
            pageData,
            pageError: null,
            isPageLoaded: true,
            routeKey,
            contentKey,
          },
          () => {
            this.propagateRouteChange(pageData)
          },
        )
      } catch (error) {
        window.scrollTo(0, 0)
        if (!this.mounted) {
          return
        }

        this.setState({ pageError: error, isPageLoaded: true }, () => {
          this.propagateRouteChange()
        })
      }
    }

    propagateRouteChange = (data) => {
      const { onRouteChange } = this.props

      const {
        pageError,
        pageData,
        pageData: { appFrame } = {},
        member,
      } = this.state

      if (pageError) {
        onRouteChange && onRouteChange({ error: pageError })
      } else {
        const { props: { _id } = {} } = data || {}
        const { settings: { technical: { trackingId } = {} } = {} } =
          appFrame || {}

        if (trackingId) {
          ReactGA.initialize(trackingId)
          ReactGA.pageview(
            window.location.pathname +
              window.location.search +
              window.location.hash,
          )
        }

        onRouteChange && onRouteChange({ _id, pageData, member })
      }
    }

    getHashPathAndParams = (hash) => {
      if (!hash) {
        return { path: '', params: {} }
      }

      hash = hash.replace('#', '')

      let [path, params] = hash.split('?')
      params =
        (params &&
          params.split('&').reduce((data, pair) => {
            const [key, value] = pair.split('=')
            return { ...data, [key]: value }
          }, {})) ||
        {}

      return { path, params }
    }

    fetchPopupData = async () => {
      const {
        location: { hash },
      } = this.props

      const { path, params } = this.getHashPathAndParams(hash)
      const { page, s, r } = params || {}
      const search = new URLSearchParams()
      if (s) search.append('s', s)
      if (r) search.append('r', r)
      const isPage = page === 'true'
      const xType = isPage ? 'page' : 'popup'

      this.setState({ isPopupLoaded: false })

      try {
        const { api } = this.props

        if (this.cancelPreviousPopupFetch) {
          this.cancelPreviousPopupFetch()
        }

        const { request, cancel } = api.connector.get(`/${path}?${search.toString()}`, {
          headers: {
            'x-type': xType,
            'x-referer': encodeURI(`${window.location.pathname}${window.location.hash}`),
          },
        })
        this.cancelPreviousPopupFetch = cancel

        const popupData = await request

        this.cancelPreviousPopupFetch = null

        if (!this.mounted) {
          return
        }

        let { component: componentName } = popupData

        let component = getComponentByName(componentName)
        if (component && component.import) {
          component = await component.import()
          component = component.default
        }

        popupData.component = component
        popupData.componentName = componentName
        popupData.props = {
          ...popupData.props,
          isPrint: this.state.isPrint,
          isPopUp: true,
        }

        this.setState(
          {
            popupAsPage: isPage,
            popupData,
            popupError: null,
            isPopupLoaded: true,
          },
          () => {
            this.propagateRouteChange(popupData)
          },
        )
      } catch (error) {
        if (!this.mounted) {
          return
        }

        this.setState({ popupError: error, isPopupLoaded: true }, () => {
          this.propagateRouteChange()
        })
      }
    }

    handlePopupClose = () => {
      const { history, location } = this.props
      history.push(location.pathname + location.search)

      this.propagateRouteChange()
    }

    callPrintDownload = async (e, isCurrentOrder) => {
      const { api, location } = this.props

      const currentUrl = `${document.location.origin}${
        document.location.pathname
      }${location.search}${location.search ? '&' : '?'}xType=print${
        isCurrentOrder ? '&currentOrder=true' : ''
      }`

      if (e && e.currentTarget && e.currentTarget.value === 'print') {
        const newWindow = window.open(currentUrl)
        setTimeout(function () {
          newWindow.print()
        }, 3000)
      } else {
        let orderedProducts = JSON.parse(
          window.localStorage.getItem('orderedProducts'),
        )
        let currentOrder = JSON.parse(
          window.localStorage.getItem('currentOrder'),
        )

        let products = []
        orderedProducts.forEach((product) => {
          if (product.amount > 0) {
            return products.push({
              _id: product._id,
              title: product.title,
              amount: product.amount,
              barcodeBundle: product.barcodeBundle,
              barcodeSingle: product.barcodeSingle,
              crmProduct: {
                price: product.crmProduct.price,
                tobaccoCategory: product.crmProduct.tobaccoCategory,
              },
            })
          }
        })

        this.setState({
          showLoadBar: true,
        })

        const { request } = api.downloadPdf(currentUrl, {
          name: location.pathname.replace(/\//g, ''),
          domain: document.location.hostname,
          orderedProducts: products,
          currentOrder: isCurrentOrder && currentOrder,
        })

        try {
          const response = await request

          this.setState({
            showLoadBar: false,
          })

          const url = window.URL.createObjectURL(new Blob([response]), {
            type: 'application/pdf',
          })
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', Date.now() + '.pdf')
          document.body.appendChild(link)
          link.click()
          link.parentNode.removeChild(link)
        } catch (error) {
          console.error(error)
        }
      }
    }

    render() {
      const {
        member,
        pageData: { componentName, appFrame = {}, appMode } = {},
        isPageLoaded,
        isPrint,
        currentOrder,
        showLoadBar,
        routeKey,
        contentKey,
      } = this.state

      const { api } = this.props

      return (
        <AppFrame
          componentName={componentName}
          mode={appMode}
          data={appFrame}
          busy={!isPageLoaded}
          routeKey={routeKey}
          contentKey={contentKey}
          showLoadBar={showLoadBar}
          member={member}
          api={api}
          isPrint={isPrint}
          currentOrder={currentOrder}
        >
          {this.renderPopup()}
          {this.renderPageContent()}
        </AppFrame>
      )
    }

    renderPopup() {
      if (!PopupWrapper) {
        return null
      }

      const {
        member,
        popupError,
        popupAsPage,
        popupData: { component, componentName, props = {} } = {},
        isPageLoaded,
        isPopupLoaded,
        showLoadBar,
      } = this.state
      const {
        location: { hash },
      } = this.props

      const isOpen = Boolean(hash && hash.length > 0)

      if (popupError) {
        const {
          response: {
            status,
            data: { navigateTo, error: { title, message } = {} } = {},
          } = {},
        } = popupError

        if (navigateTo) {
          return null
        }

        return (
          <PopupWrapper wrap isOpen={isOpen} onClose={this.handlePopupClose}>
            {renderStatusCode({ status, title, message })}
          </PopupWrapper>
        )
      }

      const Component = isPageLoaded && isPopupLoaded && component

      if (!component) {
        return null
      }

      return (
        <PopupWrapper
          componentName={Component ? componentName : undefined}
          wrap={popupAsPage}
          isOpen={isOpen}
          isLoading={Boolean(Component)}
          onClose={this.handlePopupClose}
          preventCickOutside={props && props.preventCickOutside}
        >
          {Component && (
            <Component
              {...this.props}
              {...props}
              member={member}
              onClose={this.handlePopupClose}
              callPrintDownload={this.callPrintDownload}
              showLoadBar={showLoadBar}
            />
          )}
        </PopupWrapper>
      )
    }

    renderPageContent() {
      const {
        member,
        isPageLoaded,
        pageError,
        pageData: {
          component: Component,
          props = {},
          appFrame: { settings } = {},
        } = {},
        isPrint,
        currentOrder,
      } = this.state

      // console.error('pageError -> renderPageContent -> ', pageError)

      const { theme, ...rest } = props

      if (!isPageLoaded) {
        return undefined
      }

      if (pageError) {
        const {
          response: {
            status,
            data: { navigateTo, error: { title, message } = {} } = {},
          } = {},
        } = pageError

        if (navigateTo) {
          return null
        }

        return renderStatusCode({ status, title, message })
      }

      return (
        <Component
          {...this.props}
          {...rest}
          linkTheme={theme}
          member={member}
          settings={settings}
          isPrint={isPrint}
          currentOrder={currentOrder}
          callPrintDownload={this.callPrintDownload}
        />
      )
    }
  }

  return withThemeSwitch(withApi(PageWrapper))
}

const createAsyncComponent = (importer) => ({
  import: () => importer(),
})

export { createPageWrapper, createAsyncComponent }
